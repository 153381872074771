import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rhythm } from "../utils/typography"

const PostTitle = styled.h1`
  margin-bottom: ${rhythm(1 / 4)};
`
const PostDate = styled.h3`
  font-family: merriweather;
  color: #999;
`

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <PostTitle>{post.frontmatter.title}</PostTitle>
      <PostDate>{post.frontmatter.date}</PostDate>
      <div dangerouslySetInnerHTML={{ __html: post.frontmatter.video }} />
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        video
      }
    }
  }
`
